$drawerWidth: 280px;
$drawerShrankWidth: 64px;

.separator {
  height: 1px;
  background-color: #f5f5f5;
  width: 100%; }

a, a:active {
  color: #000; }

.wrapper {
  display: flex;
  flex-direction: row;
  height: auto; }

.logo {
  &__img {
    width: auto; } }

.menuLink {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  text-decoration: none;
  box-shadow: none;
  width: $drawerWidth;
  &_bottom {
    align-self: flex-end; } }

.menuListItem {
  min-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &__svgContainer {
    width: 100%;
    height: 100%; }
  &__svg {
    fill: #fff;
    &_black {
      fill: #3f3f3f!important; } }
  & svg {
    vertical-align: middle;
    fill: #fff; }
  &:hover {
    background-color: #f5f5f5;
    & svg {
      fill: #0000ff!important; }
    & span {
      color: #0000ff; } }

  &_selected {
    background-color: #f5f5f5;
    & svg {
      fill: #0000ff; }
    & span {
      color: #0000ff; }
    &_fromPath {
      background-color: #f5f5f5;
      border-left: 3px solid #ff7300;
      & svg {
        fill: #0000ff; }
      & span {
        color: #0000ff; } } }


  &_black:hover {
    background-color: #f2f2f2; }
  &_black_selected {
    background-color: #f5f5f5;
    & svg {
      fill: #0000ff!important; }
    & span {
      color: #0000ff; }
    &_fromPath {
      background-color: #f5f5f5;
      & svg {
        fill: #0000ff; }
      & span {
        color: #0000ff; } } }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #f2f2f2; }

  &__icon {
    width: 64px;
    text-align: center;
    vertical-align: middle;
    &_black {
      width: 48px;
      color: #3f3f3f; } }

  &__textContainer {
    width: 180px; }

  &__title {
    font-size: 16px;
    font-weight: 500;
    &_black {
      color: #3f3f3f; } }

  &__svg {
    text-align: center;
    vertical-align: middle;
    width: 21px;
    height: 21px; } }

.container {
  width: $drawerWidth;
  background-color: #787878;
  -webkit-box-shadow: 4px 1px 6px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 1px 6px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 1px 6px 2px rgba(34, 60, 80, 0.2);
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  &_shrank {
    width: $drawerShrankWidth; }

  &__title {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 48px;
    max-width: 230px; }

  &__header {
    font-size: 20px;
    font-weight: 500; }

  &_white {
    background-color: #f2f2f2; }

  &__wrapper {
    width: $drawerWidth;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    &_shrank {
      width: $drawerShrankWidth;
      overflow: hidden; }

    &_white {
      background-color: #f2f2f2; } }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    flex-shrink: 0;
    background-color: #545454; }

  &__menuItems {
    &_bottom {
      height: 100%;
      display: flex; } }


  &__columns {
    position: absolute;
    left: $drawerWidth;
    z-index: 1200;
    height: 100%;
    background-color: #787878;
    display: flex;
    flex-direction: row;
    &_shrank {
      left: $drawerShrankWidth; } } }
