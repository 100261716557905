::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 5px;
  height: 0; }


::-webkit-scrollbar-track {
  background-color: #ecedee; }


::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #b9b9b9; }


::-webkit-scrollbar-thumb:hover {
  background-color: #9a9a9a; }


::-webkit-resizer {
  background-repeat: no-repeat;
  width: 4px;
  height: 0; }


::-webkit-scrollbar {
  width: 4px; }
